import React from 'react';
import Totals from '../components/totalsComponent/Totals'

const TotalsPage = () => {
    return (
        <Totals />
    );
};

export default TotalsPage;
