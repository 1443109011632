import AllOtherPayments from '../components/allOtherPaymentsComponent/AllOtherPayments'

const AllOtherPaymentsPage = () => {
    return (
        <div>
            <AllOtherPayments />
        </div>
    )
}

export default AllOtherPaymentsPage
