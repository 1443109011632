import OverPayments from '../components/overpaymentsComponent/OverPayments'

const OverpaymentsPage = () => {
    return (
        <div>
            <OverPayments />

        </div>
    )
}

export default OverpaymentsPage
