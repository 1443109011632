import OtherPayments from '../components/otherPaymentsComponent/OtherPayments'

const OtherPaymentsPage = () => {
    return (
        <div>
            <OtherPayments />
        </div>
    )
}

export default OtherPaymentsPage
