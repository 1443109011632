import React from 'react'
import AllPayments from '../components/allPaymentsComponent/AllPayments'

const AllPaymentsPage = () => {
    return (
        <div>
            <AllPayments />
        </div>
    )
}

export default AllPaymentsPage
