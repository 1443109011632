import React from 'react'
import AllOverPayments from '../components/allOverpaymentsComponent/AllOverPayments'

const AllOverpaymentsPage = () => {
    return (
        <div>
            <AllOverPayments />
        </div>
    )
}

export default AllOverpaymentsPage
