import React from 'react'

import Payments from '../components/paymentsComponent/Payments'

const PaymentsPage = () => {
    return (
        <div>
            <Payments />
        </div>
    )
}

export default PaymentsPage
